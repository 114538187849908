/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useEffect} from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import { ImPrinter } from 'react-icons/im';
import {SuccessDiamondDetails} from "./SuccessDiamondDetails"
import axios from "axios"
import { NODE_API_URL } from "../../../env_config";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";
export const PaymentSuccessNewCustomer = (props) => {
    const params = useLocation()
    const [invoicedetail,setInvoiceDetails] = useState([])
    const [orderdetail,setOrderDetails] = useState([])
    const [invoicelink,setInvoiceLink] = useState("")
    const [userinfo,setUserInfo] = useState(null)
    const [vipdiscount] = useState(0)
    const [loader,setLoader] = useState(false)
    console.log(props.paymentSuccessNewRef,"props.paymentSuccessNewRef")
    useEffect(() => {
        
        // Ensure `paymentSuccessNewRef` is initialized and checked properly
        if (props.paymentSuccessNewRef?.current) {
            return;
        }
        if(!props.userinfo.id){
            return;
        }

        // Update paymentSuccessNewRef to true so API doesn't get called again
        props.paymentSuccessNewRef.current = true;

        if (params && params.search) {
            setLoader(true);
            const sessionid = params.search.split("?session_id=")[1];

            // Check if session_id is valid
            if (!sessionid) {
                setLoader(false);
                return;
            }

            // Debugging: Log to check when API call is made

            axios({
                method: "POST",
                url: `${NODE_API_URL}/INTL/createInvoice`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    stripeToken: sessionid
                }
            })
            .then(function (res) {
                setLoader(false); // Stop loading spinner

                // Check if response is valid and set the state accordingly
                if (res && res.data && res.data.data && res.data.data.msg === "Invoice generated") {
                    setInvoiceDetails(res.data.data.invoice_data);
                    setOrderDetails(res.data.data.order_data);
                    setInvoiceLink(res.data.data.invoice_link);
                    setUserInfo(res.data.data.userinfo);
                }
            })
            .catch(function (error) {
                setLoader(false); // Stop loading spinner on error
            });
        }
    }, [params, props]); 
    const GetPDF = () => {
        if(process.env.REACT_APP_ENV === "production"){
            window.open(`https://assets.diamondsoncall.com/assets/pdf_invoice/${invoicelink}.pdf`, "_blank")
        }
        else{
            window.open(`https://alpha.diamondsupload.com/assets/pdf_invoice/${invoicelink}.pdf`, "_blank")
        }
    }
    function numberWithCommas(x) {
    if(x){
        return parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else{
        return "0.00"
    }
}
function formatAddressForEmail(address) {
    return address?.replaceAll("<br>",", ").split(', ')
      .map(line => line.trim())  // Trim any extra spaces
      .join('<br>');  // Join the lines with <br> tags for HTML line breaks
  }

    return (
        <>
            
            {loader?<div className="px-5 py-3 bg-white rounded shadow-sm d-flex justify-content-center align-items-center"><CircularProgress size="30px" color="secondary" /> <span className="pl-2 font-weight-bold text-dark">Please Wait While Invoice is Been Generated..</span></div>:invoicedetail.length > 0 && orderdetail.length > 0?<div className="card bg-white custom-card">
                <div className="card-body">
                    <div className="row mt-5 justify-content-center mb-10">
                        <div className="col-12 text-center">
                            <SVG  height={50}  width={50}  src={toAbsoluteUrl("/media/General/ic_fest.svg")} />
                            <div className="display-3 mt-4 text-primary">{props.language.PAYMENT_SUCCESS_THANKYOU}</div>
                            <p className="text-dark mt-4 font-weight-bold">{props.language.PAYMENT_SUCCESS_PAR1} <strong>{orderdetail[0].invoicenumber}</strong></p>
                            <p className="text-dark mt-4 font-weight-bold">{props.language.PAYMENT_SUCCESS_PAR2} {userinfo?.mail} {props.language.PAYMENT_SUCCESS_PAR3}</p>
                            <p className="text-muted mt-4 font-weight-bold">{props.language.PAYMENT_SUCCESS_PLACEDON} : {orderdetail[0].order_date.split('T')[0]} </p>
                            {invoicelink?<a className="mt-4 font-weight-bold" onClick={() => GetPDF(orderdetail[0].invoicenumber)} disabled={!invoicelink}>{props.language.PAYMENT_SUCCESS_VIEW_INVOICE} <ImPrinter className="ml-2 font-size-lg"/></a>:<a className="mt-4 font-weight-bold">{props.language.PAYMENT_SUCCESS_GENERATING_INVOICE} ...<ImPrinter className="ml-2 font-size-lg"/></a>}
                        </div>
                        <div className="col-md-8 border-top pt-5 mt-5">
                            <div className="row mt-5">
                                <div className="col-md-4 border-md-right">
                                    <div className="font-size-h3 mb-4 font-weight-bold">{props.language.CHECKOUT_DELIVERY_INFORMATION}</div>
                                    <div>
                                    <div className="text-capitalize">
                                            <div>
                                                <div dangerouslySetInnerHTML={ {__html:  formatAddressForEmail(orderdetail[0]?.shipping_address)} } />
                                            </div>
                                         <br />
                                    </div>
                                    </div>
                                </div> 
                                <div className="col-md-4 border-md-right">
                                    <div className="font-size-h3 mb-4 font-weight-bold">{props.language.CHECKOUT_BILLING_INFORMATION}</div>
                                    <div>
                                        <div dangerouslySetInnerHTML={ {__html:  formatAddressForEmail(orderdetail[0]?.billing_address)} } />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="font-size-h3 mb-4 font-weight-bold">{props.language.CHECKOUT_SHIPPING_METHOD}</div>
                                    <p className="mb-2 font-weight-bold">{orderdetail[0].pre_carriage}</p>
                                    {/* <p>( Delivery will be in 1-2 days, If delay well notify you )</p> */}
                                </div> 
                            </div>
                        </div>
                        <div className="col-md-8 mt-5 mt-md-10 border-bottom pb-5 mt-5">
                            <div className="font-weight-bold mb-4 font-size-h3">{props.language.PROFORMA_DETAIL_ORDER_SUMMARY}<span className="small ml-2">({invoicedetail.length} {props.language.CHECKOUT_ITEMS})</span></div>
                            <SuccessDiamondDetails data={invoicedetail} language={props.language}/>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-12 d-flex mt-2 justify-content-between font-weight-bold">
                                    <div> {props.language.PROFORMA_DETAIL_SUB_TOTAL}: </div>
                                    <div> ${numberWithCommas(Math.round((parseFloat(orderdetail[0].total_amt) - parseFloat(orderdetail[0].shipping_charge) - parseFloat(orderdetail[0].credit_card_charge) + parseFloat(orderdetail[0].discount_extra)) * 100)/100)} </div>
                                </div>
                                {parseFloat(orderdetail[0].shipping_charge)?<div className="col-12 d-flex mt-2 justify-content-between font-weight-bold">
                                    <div> {props.language.REQ_DIAMOND_CONVINIENCE_FEE}: </div>
                                    <div> ${numberWithCommas(orderdetail[0].shipping_charge)} </div>
                                </div>:""}
                                {orderdetail[0].discount_extra !== "0"?<div className="col-12 d-flex mt-2 justify-content-between font-weight-bold">
                                    <div> {props.language.INVOICE_DISCOUNT}: </div>
                                    <div> ${numberWithCommas(orderdetail[0].discount_extra)} </div>
                                </div>:""}
                                {vipdiscount?<div className="col-12 d-flex mt-2 justify-content-between font-weight-bold border-bottom border-top py-3">
                                    <div className="font-size-lg"> {props.language.INVOICE_SERVICE_CHARGE_DISCOUNT}: </div>
                                    <div className="font-size-lg text-danger"> -${numberWithCommas(vipdiscount)} </div>
                                </div>:""}
                                <div className="col-12 d-flex mt-2 justify-content-between font-weight-bold border-bottom border-top py-3">
                                    <div className="font-size-lg"> {props.language.SEARCHGRID_TOTAL}: </div>
                                    <div className="font-size-lg"> ${numberWithCommas(orderdetail[0].total_amt - orderdetail[0].credit_card_charge - vipdiscount)} </div>
                                </div>
                                <div className="col-12 d-flex mt-2 justify-content-between font-weight-bold border-bottom border-top py-3">
                                    <div className="font-size-lg"> {props.language.INVOICE_CREDIT_CARD_CHARGES}: </div>
                                    <div className="font-size-lg"> ${numberWithCommas(orderdetail[0].credit_card_charge)} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>:
            <div className="card bg-white custom-card">
            <div className="card-body">
                <div className="row mt-5 justify-content-center mb-10">
                    <div className="col-12 text-center">
                        <div className="display-3 mt-4 text-primary">FORBIDDEN</div>
                    </div>
                </div>
            </div>
        </div>
            }

        </>
    );
};
