/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { FaCog,FaTrashAlt,FaRegEdit } from 'react-icons/fa';
// import { BsLayoutTextWindowReverse } from 'react-icons/bs';
import {ShortListTable} from "./ShortList/ShortListTable"
import { useHistory ,useLocation} from "react-router-dom";
import { OverlayTrigger, Tooltip,Alert } from "react-bootstrap";
import { PasswordUpdatePopUp } from "../Popup/PasswordUpdatePopUp";
import Cookies from "js-cookie"
import {
    Switch,
    FormControlLabel,
    Tabs,
    Tab,
    Typography
  } from '@material-ui/core';
  import PropTypes from "prop-types";
// import CcmodeSetupFrom from "./CcmodeSetupNew/CcmodeSetupFrom"
import axios from "axios";
import { NODE_API_URL ,BASE_URL } from "../../../env_config";
import { SwalWarn } from "../Popup";
import { toast } from "react-toastify";

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export const CcmodeDashboard = (props) => {
    const history = useHistory()
    const location = useLocation()
    const [updaterule,setUpdateRule] = useState("")
    const [passwordupdate,setPasswordUpdate] = useState(false)
    useEffect(() => {
        if(location.state && location.state.updatestatus){
            setUpdateRule("Please search again on CC MODE  for latest rule changes")
        }
        window.history.replaceState({}, document.title)
        if(props.ccmodesetting && props.ccmodesetting.length && !props.ccmodesetting[0].Password){
            setPasswordUpdate(true)
        }   
    },[])
    console.log(passwordupdate,"passwordupdate")
    const [swalopen,setSwalOpen] = useState(false)
    const [deleteObj,setDeleteObj] = useState(null)
    const [value, setValue] = useState(location.state && location.state.backfromShortlist ? 1 : 0);
    // console.log(props.ccmodesetting[0])
    const DeleteRule = () => {
        axios({
            method: "POST",
            url: `${NODE_API_URL}/INTL/delete-ccmode-rules`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data: {
                user_id:deleteObj.user_id,
                rule_id:deleteObj.rule_id,
                activity_type:"RuleDeleted",
                user_agent:navigator.userAgent
            },
        }).then(res => {
            if(res && res.data){
                // console.log(res.data.data,"CCMODEEEEWHITELABEL")
                props.setUpdateRules(!props.updaterule)
                setSwalOpen(false)
                setDeleteObj(null)
            }
        }).catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
              var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
              if(cookies[i] && !cookies[i].includes("view")){
                var equals = cookies[i].indexOf("=");
                var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
            }
            
            window.location.replace(`${BASE_URL}/logout`);
          }
          })
    }
    const SetDeleteRule = (rule) => {
        setDeleteObj(rule)
        setSwalOpen(true)
    }
    function handleTabChange(event, newValue) {
        window.glob = newValue
        setValue(newValue);
    }
    const UpdateStatus = (rule) => {
        let rulestatus
        if(rule.status === 1){
            rulestatus = 0
        }
        else{
            rulestatus = 1
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/INTL/update-ccmode-rule-status`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data: {
                rule_id:rule.rule_id,
                status:rulestatus
            },
        }).then(res => {
            if(res && res.data && res.data.data){
                // console.log(res.data.data,"CCMODEEEEWHITELABEL")
                props.setUpdateRules(!props.updaterule)
                if (rulestatus === 1) {
                    toast.success(`Rule Name ${rule.rule_name} turned on`)
                }
                else {
                    toast.error(`Rule Name ${rule.rule_name} turned off`)
                }
            }
        })
        .catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
              var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
              if(cookies[i] && !cookies[i].includes("view")){
                var equals = cookies[i].indexOf("=");
                var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
            }
            
            window.location.replace(`${BASE_URL}/logout`);
          }
          })
    }
    return (
        <>
        <PasswordUpdatePopUp show={passwordupdate} userinfo={props.userinfo} setPasswordUpdate={setPasswordUpdate}/>
        {updaterule?<Alert variant="success">
                    {updaterule}
              </Alert>:""}
             
            <SwalWarn show={swalopen} message={"Are you sure want to delete Rule?"} onHide={() => setSwalOpen(false)} onClose={() => setSwalOpen(false)} responseOnOk={() => DeleteRule()}/>
            <div className="row align-content-start position-relative" style={{minHeight: "74vh"}}>
                <div className="col-12 pb-4">
                    <h3>CCMode Dashboard</h3>
                </div>
                <div className="col-12 rounded bg-white d-md-flex align-items-center justify-content-between border-bottom"> 
                   {/* <Paper position="static "> */}
                        <Tabs value={value} onChange={handleTabChange}>
                            <Tab label="Mark-up rules Manager" />
                            {props.ccmodesetting[0].AllowLogin.data == 1 ?
                                <Tab label="Session Detail" />
                            :null}

                            {/* {user.NaturalDiamondType==1 || user.NaturalFancyDiamondType==1 ? ( <Tab label="Natural" /> ) : ( '' )}
                            {user.LabDiamondType==1 || user.LabFancyDiamondType==1  ? ( <Tab label="Lab Grown" /> ) : ( '' )} */}
                        </Tabs>
                    {/* </Paper> */}
                    <div>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    Ccmode Settings
                                </Tooltip>
                            }
                        >
                            <button className="btn btn-text-dark btn-hover-light-dark"  onClick={() => history.push({ pathname: "/ccmode-setting", state: "ccmodesetting"})}><FaCog className="font-size-h4 mr-2"/>Settings</button>
                        </OverlayTrigger>
                    </div>
                    {/* <CcmodeSetupFrom userinfo={props.userinfo} language={props.language}/> */}
                </div>
                    {value === 0 && (
                        <div className="col-12 bg-white">
                            <TabContainer>
                                {props.ccmoderules.length ===0 ? <Alert variant="danger">
                                Please setup at least one rules to login to CC-Mode
                                </Alert>:"" }
                                {props.ccmoderules.map(value => value.status).every(element => element === 0) ? <Alert variant="danger">
                                Please activate at least one rule to Login to CC-Mode
                                </Alert>:"" }
                                <div className="mt-2">Your Multi-level Markup for different types of Diamonds</div>
                                <div className="table-responsive w-100 mt-5">
                                    <table className="table table-hover">
                                        <thead className="custom_datatable_head">
                                            <tr>
                                                <th className="text-left align-middle">Diamond Type</th>
                                                <th className="text-center align-middle">Diamond Criteria</th>
                                                <th className="text-center align-middle">Rule Status</th>
                                                <th className="text-center align-middle">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.ccmoderules.filter(val => val.naturaldiamond === 1).length?<tr>
                                                <td className="text-left align-middle">Natural Colorless Diamonds</td>
                                                <td className="text-center align-middle">
                                                    {props.ccmoderules.filter(val => val.naturaldiamond === 1)[0].shape.toString().slice(0,10) + "... | " + props.ccmoderules.filter(val => val.naturaldiamond === 1)[0].min_carat + '-' + props.ccmoderules.filter(val => val.naturaldiamond === 1)[0].max_carat + " | " + (props.ccmoderules.filter(val => val.naturaldiamond === 1)[0].naturaldiamond?props.ccmoderules.filter(val => val.naturaldiamond === 1)[0].color.toString().slice(0,10):props.ccmoderules.filter(val => val.naturaldiamond === 1)[0].diamondfancy_color.toString().slice(0,10)) + "... | " + props.ccmoderules.filter(val => val.naturaldiamond === 1)[0].clarity.toString().slice(0,10) + "..."}
                                                </td>
                                                <td className="text-center align-middle">
                                                    <Switch
                                                        checked={props.ccmoderules.filter(val => val.naturaldiamond === 1)[0].status === 1}
                                                        onClick={() => UpdateStatus(props.ccmoderules.filter(val => val.naturaldiamond === 1)[0])}
                                                        value={1}
                                                        color="secondary"
                                                        // disabled={disabled}
                                                    />
                                                </td>
                                                <td className="text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to edit rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" onClick={() => history.push({ pathname: "/ccmode-update-rule",state: {
                                                        rule_name:"Natural White Rule",
                                                        diamond_type:"N",
                                                        naturaldiamond:1,
                                                        naturalfancydiamond:0,
                                                        labdiamond:0,
                                                        labfancydiamond:0,
                                                        rule_id:props.ccmoderules.filter(val => val.naturaldiamond === 1)[0].rule_id,
                                                        currency:props.ccmodesetting && props.ccmodesetting.length?props.ccmodesetting[0].Currency:"USD"
                                                    }})}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to delete rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm" onClick={() => SetDeleteRule(props.ccmoderules.filter(val => val.naturaldiamond === 1)[0])}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>:<tr>
                                                <td className="text-left align-middle">Natural Colorless Diamonds</td>
                                                <td className="text-center align-middle">
                                                    <button className="btn btn-primary btn-sm" onClick={() => history.push({ pathname: "/ccmode-create-rule",state: {
                                                        rule_name:"Natural White Rule",
                                                        diamond_type:"N",
                                                        naturaldiamond:1,
                                                        naturalfancydiamond:0,
                                                        labdiamond:0,
                                                        labfancydiamond:0,
                                                        currency:props.ccmodesetting && props.ccmodesetting.length?props.ccmodesetting[0].Currency:"USD"

                                                    }})}>Add Rule</button>
                                                </td>
                                                <td className="text-center align-middle">
                                                    <FormControlLabel
                                                        className='m-0'
                                                        control={
                                                            <Switch
                                                                // checked={}
                                                                // onClick={handleChange}
                                                                value={1}
                                                                color="secondary"
                                                                disabled={true}
                                                            />
                                                        }
                                                    />
                                                </td>
                                                <td className="text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to edit rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" disabled={true}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to delete rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm" disabled={true}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>}
                                            {props.ccmoderules.filter(val => val.naturalfancydiamond === 1).length?<tr>
                                                <td className="text-left align-middle">Natural Fancy Color Diamonds</td>
                                                <td className="text-center align-middle">
                                                {props.ccmoderules.filter(val => val.naturalfancydiamond === 1)[0].shape.toString().slice(0,10) + "... | " + props.ccmoderules.filter(val => val.naturalfancydiamond === 1)[0].min_carat + '-' + props.ccmoderules.filter(val => val.naturalfancydiamond === 1)[0].max_carat + " | " + (props.ccmoderules.filter(val => val.naturalfancydiamond === 1)[0].naturaldiamond?props.ccmoderules.filter(val => val.naturalfancydiamond === 1)[0].color.toString().slice(0,10):props.ccmoderules.filter(val => val.naturalfancydiamond === 1)[0].diamondfancy_color.toString().slice(0,10)) + "... | " + props.ccmoderules.filter(val => val.naturalfancydiamond === 1)[0].clarity.toString().slice(0,10) + "..."}
                                                </td>
                                                <td className="text-center align-middle">
                                                    <Switch
                                                        checked={props.ccmoderules.filter(val => val.naturalfancydiamond === 1)[0].status === 1}
                                                        onClick={() => UpdateStatus(props.ccmoderules.filter(val => val.naturalfancydiamond === 1)[0])}
                                                        value={1}
                                                        color="secondary"
                                                    // disabled={disabled}
                                                    />
                                                </td>
                                                <td className="text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to edit rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" onClick={() => history.push({ pathname: "/ccmode-update-rule",state: {
                                                        rule_name:"Natural Fancy Diamonds",
                                                        diamond_type:"N",
                                                        naturaldiamond:0,
                                                        naturalfancydiamond:1,
                                                        labdiamond:0,
                                                        labfancydiamond:0,
                                                        rule_id:props.ccmoderules.filter(val => val.naturalfancydiamond === 1)[0].rule_id,
                                                        currency:props.ccmodesetting && props.ccmodesetting.length?props.ccmodesetting[0].Currency:"USD"

                                                    }})}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to delete rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm" onClick={() => SetDeleteRule(props.ccmoderules.filter(val => val.naturalfancydiamond === 1)[0])}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>:<tr>
                                                <td className="text-left align-middle">Natural Fancy Color Diamonds</td>
                                                <td className="text-center align-middle">
                                                    <button className="btn btn-danger btn-sm" onClick={() => history.push({ pathname: "/ccmode-create-rule",state: {
                                                        rule_name:"Natural Fancy Rule",
                                                        diamond_type:"N",
                                                        naturaldiamond:0,
                                                        naturalfancydiamond:1,
                                                        labdiamond:0,
                                                        labfancydiamond:0,
                                                        currency:props.ccmodesetting && props.ccmodesetting.length?props.ccmodesetting[0].Currency:"USD"

                                                    }})}>Add Rule</button>
                                                </td>
                                                <td className="text-center align-middle">
                                                    <FormControlLabel
                                                        className='m-0'
                                                        control={
                                                            <Switch
                                                                // checked={}
                                                                // onClick={handleChange}
                                                                value={1}
                                                                color="secondary"
                                                                disabled={true}
                                                            />
                                                        }
                                                    />
                                                </td>
                                                <td className="text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to edit rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" disabled={true}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to delete rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm" disabled={true}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>}
                                            {props.ccmoderules.filter(val => val.labdiamond === 1).length?<tr>
                                                <td className="text-left align-middle">LAB Colorless Diamonds</td>
                                                <td className="text-center align-middle">
                                                    {props.ccmoderules.filter(val => val.labdiamond === 1)[0].lab_shape.toString().slice(0,10) + "... | " + props.ccmoderules.filter(val => val.labdiamond === 1)[0].lab_min_carat + '-' + props.ccmoderules.filter(val => val.labdiamond === 1)[0].lab_max_carat + " | " + (props.ccmoderules.filter(val => val.labdiamond === 1)[0].labdiamond?props.ccmoderules.filter(val => val.labdiamond === 1)[0].lab_color.toString().slice(0,10):props.ccmoderules.filter(val => val.labdiamond === 1)[0].lab_fancy_color.toString().slice(0,10)) + "... | " + props.ccmoderules.filter(val => val.labdiamond === 1)[0].lab_clarity.toString().slice(0,10) + "..."}
                                                </td>
                                                <td className="text-center align-middle">
                                                    <Switch
                                                        checked={props.ccmoderules.filter(val => val.labdiamond === 1)[0].status === 1}
                                                        onClick={() => UpdateStatus(props.ccmoderules.filter(val => val.labdiamond === 1)[0])}
                                                        value={1}
                                                        color="secondary"
                                                    // disabled={disabled}
                                                    />
                                                </td>
                                                <td className="text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to edit rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" onClick={() => history.push({ pathname: "/ccmode-update-rule",state: {
                                                        rule_name:"Lab Grown White Diamonds",
                                                        diamond_type:"L",
                                                        naturaldiamond:0,
                                                        naturalfancydiamond:0,
                                                        labdiamond:1,
                                                        labfancydiamond:0,
                                                        rule_id:props.ccmoderules.filter(val => val.labdiamond === 1)[0].rule_id,
                                                        currency:props.ccmodesetting && props.ccmodesetting.length?props.ccmodesetting[0].Currency:"USD"

                                                    }})}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to delete rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm" onClick={() => SetDeleteRule(props.ccmoderules.filter(val => val.labdiamond === 1)[0])}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>:<tr>
                                                <td className="text-left align-middle">LAB Colorless Diamonds</td>
                                                <td className="text-center align-middle">
                                                    <button className="btn btn-success btn-sm" onClick={() => history.push({ pathname: "/ccmode-create-rule",state: {
                                                        rule_name:"Lab White Rule",
                                                        diamond_type:"L",
                                                        naturaldiamond:0,
                                                        naturalfancydiamond:0,
                                                        labdiamond:1,
                                                        labfancydiamond:0,
                                                        currency:props.ccmodesetting && props.ccmodesetting.length?props.ccmodesetting[0].Currency:"USD"

                                                    }})}>Add Rule</button>
                                                </td>
                                                <td className="text-center align-middle">
                                                    <FormControlLabel
                                                        className='m-0'
                                                        control={
                                                            <Switch
                                                                // checked={}
                                                                // onClick={handleChange}
                                                                value={1}
                                                                color="secondary"
                                                                disabled={true}
                                                            />
                                                        }
                                                    />
                                                </td>
                                                <td className="text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to edit rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" disabled={true}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to delete rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm" disabled={true}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>}
                                            {props.ccmoderules.filter(val => val.labfancydiamond === 1).length?<tr>
                                                <td className="text-left align-middle">LAB Fancy Color Diamonds</td>
                                                <td className="text-center align-middle">
                                                {props.ccmoderules.filter(val => val.labfancydiamond === 1)[0].lab_shape.toString().slice(0,10) + "... | " + props.ccmoderules.filter(val => val.labfancydiamond === 1)[0].lab_min_carat + '-' + props.ccmoderules.filter(val => val.labfancydiamond === 1)[0].lab_max_carat + " | " + (props.ccmoderules.filter(val => val.labfancydiamond === 1)[0].labdiamond?props.ccmoderules.filter(val => val.labfancydiamond === 1)[0].lab_color.toString().slice(0,10):props.ccmoderules.filter(val => val.labfancydiamond === 1)[0].lab_fancy_color.toString().slice(0,10)) + "... | " + props.ccmoderules.filter(val => val.labfancydiamond === 1)[0].lab_clarity.toString().slice(0,10) + "..."}
                                                </td>
                                                <td className="text-center align-middle">
                                                    <Switch
                                                        checked={props.ccmoderules.filter(val => val.labfancydiamond === 1)[0].status === 1}
                                                        onClick={() => UpdateStatus(props.ccmoderules.filter(val => val.labfancydiamond === 1)[0])}
                                                        value={1}
                                                        color="secondary"
                                                    // disabled={disabled}
                                                    />
                                                </td>
                                                <td className="text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to edit rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" onClick={() => history.push({ pathname: "/ccmode-update-rule",state: {
                                                        rule_name:"Lab Fancy Rule",
                                                        diamond_type:"L",
                                                        naturaldiamond:0,
                                                        naturalfancydiamond:0,
                                                        labdiamond:0,
                                                        labfancydiamond:1,
                                                        rule_id:props.ccmoderules.filter(val => val.labfancydiamond === 1)[0].rule_id,
                                                        currency:props.ccmodesetting && props.ccmodesetting.length?props.ccmodesetting[0].Currency:"USD"

                                                    }})}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to delete rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm" onClick={() => SetDeleteRule(props.ccmoderules.filter(val => val.labfancydiamond === 1)[0])}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>:<tr>
                                                <td className="text-left align-middle">LAB Fancy Color Diamonds</td>
                                                <td className="text-center align-middle">
                                                    <button className="btn btn-warning btn-sm" onClick={() => history.push({ pathname: "/ccmode-create-rule",state: {
                                                        rule_name:"Lab Fancy Rule",
                                                        diamond_type:"L",
                                                        naturaldiamond:0,
                                                        naturalfancydiamond:0,
                                                        labdiamond:0,
                                                        labfancydiamond:1,
                                                        currency:props.ccmodesetting && props.ccmodesetting.length?props.ccmodesetting[0].Currency:"USD"
                                                    }})}>Add Rule</button>
                                                </td>
                                                <td className="text-center align-middle">
                                                    <FormControlLabel
                                                        className='m-0'
                                                        control={
                                                            <Switch
                                                                // checked={}
                                                                // onClick={handleChange}
                                                                value={1}
                                                                color="secondary"
                                                                disabled={true}
                                                            />
                                                        }
                                                    />
                                                </td>
                                                <td className="text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to edit rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" disabled={true}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Click to delete rule
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm" disabled={true}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </TabContainer>
                        </div>
                    )}
                    {props.ccmodesetting[0].AllowLogin.data == 1 ?
                        value === 1 && (
                            <div className="col-12 bg-white">
                                <TabContainer>
                                    <ShortListTable userinfo={props.userinfo} language={props.language}/>
                                </TabContainer>
                            </div>
                        )
                        :null
                    }
            </div>
        </>
    );
};

