import React from 'react';
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {Switch,FormControlLabel  } from '@material-ui/core';
import { NODE_API_URL,BASE_URL } from '../../../../env_config';
import axios from 'axios';
import Cookies from "js-cookie";
import moment from "moment-timezone";

function countryGroup (x) {
    var country = x.split(',').map(function (x, index) {
        return <span className="label label-outline-primary label-pill label-inline bg-primary-o-30 px-5 mr-1" key={index}>{ x }</span>; 
    });

    return <span>{country}</span>;
}

function dateFormate(date){
    let formattedDate = "";
    if (date) {
        let dateObj = new Date(date);
        dateObj = new Date(dateObj.getTime() - (330 * 60 * 1000))
        const year = dateObj.getFullYear();
        const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
        const day = ('0' + dateObj.getDate()).slice(-2);
        const timeString = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        formattedDate = `${year}-${month}-${day} ${timeString}`;
    }
    return formattedDate;
}    
export const MockShortListColumn = (setUpdate,update,stockexpire,toast,language,userinfo,feedsetting) => [

    {
        Header:language.DIAMOND_FEED_LAB_LISTING_SUPPNAME,
        accessor:'supplier_name_o',
        className:"align-middle text-center",
    },
    {
        Header:language.SEARCHLIST_LOCATION,
        accessor: (d) => {
            return (
                <>
                    {d.diamond_countries === null && d.country === null 
                    ?<>-</>
                    :<>
                        {d.diamond_countries?countryGroup(d.diamond_countries):<span className="label label-outline-primary label-pill label-inline bg-primary-o-30 px-5">{d.country}</span>}
                    </>}
                </>
            );
        },
        className:"align-middle text-center",
    },
    {
        Header:language.PROFORMA_DETAIL_DIAMONDS,
        accessor: (d) => {
            return (
                <>
                    {d.whitetotal > 0 ?<>White - {d.whitetotal} {d.fancytotal > 0 ?<>| Fancy - {d.fancytotal}</>:"" }</>:"White - 0" }
                </>
            );
        },
        className:"text-center align-middle",
    },
    {
        Header:language.DIAMOND_FEED_LAB_LISTING_FEEDSTATUS,
        accessor: (d) => {
            return (
                <>
                    {d.status === 1 ?<span class="label label-danger label-pill label-inline px-5">In Active</span>
                    : d.api_status === 1 || d.stock_status === 1 ? <span class="label label-success label-pill label-inline px-5">Active</span>
                    : d.api_status === 2 ? <span class="label label-danger label-pill label-inline px-5">In Active</span>
                    : d.api_status === 0 ? <span class="label label-danger label-pill label-inline px-5">Pending</span>
                    : "-" }
                </>
            );
        },
        className:"text-center align-middle",
    },
    {
        Header:language.DIAMOND_FEED_LAB_LISTING_FEEDONOFF,
        accessor: (d) => {
            const handleChange = (e) => {
                // console.log(d.value,"Changes")
                let switchvalue
                if(d.api_on_off === 1){
                    switchvalue = 2
                }else{
                    switchvalue = 1
                }
                // console.log(switchvalue,"switchvalue",update)
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/INTL/update-Data-From-SupReqTable`,
                    headers: {
                        "Content-Type": "application/json",
                         "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                    },
                    data: {
                        "fields": [
                            {
                                "api_on_off": switchvalue
                            }
                        ],
                        "condition": [
                            {
                                "sr_id": d.sr_id
                            }
                        ]
                    },
                }).then(supreq => {
                    if(supreq && supreq.data){
                        if(supreq.data){
                        setUpdate(d)
                        if(switchvalue === 1){
                            toast.success("Your Feed Turn on")
                        }else{
                            toast.error("Your Feed Turn Off")
                        }
                        if(feedsetting.feed_type === "API"){
                            axios({
                                method: "POST",
                                url: feedsetting.feed_type === "API"?`${NODE_API_URL}/INTL/insert-Supplier-From-SupplierManagement`:`${NODE_API_URL}/INTL/insert-supplier-from-supplierManagementIFrame`,
                                headers: {
                                    "Content-Type": "application/json",
                                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                                },
                                data: {
                                    "on_off":switchvalue,
                                    "supplier_name":d.supplier_name
                                },
                            }).then(res => {
                                console.log(res,"RETST")
                            }).
                            catch(error => {
                              if(error?.response?.data?.message === "UnAuthorized!"){
                                var cookies = document.cookie.split(";");
                              for (var i = 0; i < cookies.length; i++) {
                                if(cookies[i] && !cookies[i].includes("view")){
                                  var equals = cookies[i].indexOf("=");
                                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                                }
                              }
                              
                              window.location.replace(`${BASE_URL}/logout`);
                            }
                            });
                        }
                        
                        }
                    }
                }).
                catch(error => {
                  if(error?.response?.data?.message === "UnAuthorized!"){
                    var cookies = document.cookie.split(";");
                  for (var i = 0; i < cookies.length; i++) {
                    if(cookies[i] && !cookies[i].includes("view")){
                      var equals = cookies[i].indexOf("=");
                      var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                    }
                  }
                  
                  window.location.replace(`${BASE_URL}/logout`);
                }
                });
            }
            let checked = false
            let disabled = false
            if(d.api_on_off === 1){
                checked = true
            }
            if(d.stock_access_status === 3 || d.status === 1 || d.stock_status === 1 || d.api_status === 2){
                disabled = true
                checked = false
            }
            else if(d.req_status == 1 && d.api_status == 1){
                disabled = false
                
            }else if(d.stock_access_status == 2){
                
                disabled = true
                checked = false

            }else{
                disabled = false
            }
            return (
                <>
                     {/* <FormGroup row> */}
                     <FormControlLabel
                        className='m-0'
                        control={
                        <Switch
                            checked={checked}
                            onClick={handleChange}
                            value={d.api_on_off}
                            color="secondary"
                            disabled={disabled}
                        />
                        }
                        // label="Primary"
                    />
                     {/* </FormGroup> */}
                </>
            );
        },
        className:"text-center align-middle",
    },
    {
        Header:language.DIAMOND_FEED_LAB_LISTING_REQUESTFEED,
        accessor: (d) => {
            const requestfeed = () => {
        const userData = JSON.parse(localStorage.getItem("userData"))
        let isoDate = new Date();
        isoDate = new Date(isoDate.getTime())
        const mySQLDateString = isoDate.toJSON().slice(0, 19).replace('T', ' ');
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/INTL/update-Data-From-SupReqTable`,
                    headers: {
                        "Content-Type": "application/json",
                         "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                    },
                    data: {
                        "fields": [
                            {
                                "req_status": 3
                            },
                            {
                                "updated_date":mySQLDateString
                            }
                        ],
                        "condition": [
                            {
                                "sr_id": d.sr_id
                            }
                        ]
                    },
                }).then(supreq => {
                    if(supreq && supreq.data){
                        if(supreq.data){
                        setUpdate(d)
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/INTL/update-Data-From-supMail`,
                            headers: {
                                "Content-Type": "application/json",
                                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                            },
                            data: {
                                "supplier_id": d.supplier_id,
                                "sr_id": d.sr_id,
                                "req_status": 3
                            },
                        }).then(mail => {
                        }).
                        catch(error => {
                          if(error?.response?.data?.message === "UnAuthorized!"){
                            var cookies = document.cookie.split(";");
                          for (var i = 0; i < cookies.length; i++) {
                            if(cookies[i] && !cookies[i].includes("view")){
                              var equals = cookies[i].indexOf("=");
                              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                            }
                          }
                          
                          window.location.replace(`${BASE_URL}/logout`);
                        }
                        });
                        }
                    }
                }).
                catch(error => {
                  if(error?.response?.data?.message === "UnAuthorized!"){
                    var cookies = document.cookie.split(";");
                  for (var i = 0; i < cookies.length; i++) {
                    if(cookies[i] && !cookies[i].includes("view")){
                      var equals = cookies[i].indexOf("=");
                      var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                    }
                  }
                  
                  window.location.replace(`${BASE_URL}/logout`);
                }
                });
            }
            // console.log(d.supplier_name_o,"OOOOOO")
            // console.log(value.diasene_id,"value.diasene_id")
            let expiry = null
            const expire_times = stockexpire.find(value => value.supplier_name_o.toString() === d.supplier_name_o.toString())
            // console.log(expire_times,"expire_timesexpire_times")
            if(expire_times && expire_times.disanceobj && expire_times.disanceobj.length > 0){
                expiry = expire_times.disanceobj[0].expire_time
            }
            if(expiry){
                // console.log(expiry,"EXPERTISE",d.supplier_name_o)
            }
            return (
                <>
                    {d.req_status === 4 ? <button class="btn btn-primary btn-sm" onClick={() => requestfeed()}>Request Feed</button>
                    : d.req_status === 2 ? <><span class="text-danger font-weight-bold">Access Denied</span><br /><span className="small text-muted px-2"> On { d.updated_date ? moment.tz(d.updated_date, userinfo.timezone ? userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}</span></>
                    : d.stock_access_status === 2 && d.req_status === 3 ? <><span class="text-warning font-weight-bold">Requested</span>  <br /><span className="small text-muted px-2"> On {d.updated_date ? moment.tz(d.updated_date, userinfo.timezone ? userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}</span></>
                    : d.status === 1 ? <span class="text-danger font-weight-bold d-inline-block px-2 py-0">Supplier Account Inactive</span>
                    : d.stock_status === 1 ? <span class="text-danger font-weight-bold d-inline-block px-2 py-0">Supplier Account Inactive</span>
                    : expiry ? <><span class="text-danger font-weight-bold d-inline-block px-2 py-0">Stock Expired </span>  <br /><span className="small text-muted px-2"> On {expiry}</span></>
                    : d.req_status === 1  &&   d.api_status === 1 ? <><span class="text-success font-weight-bold font-weight-bold">Approved</span>  <br /><span className="small text-muted px-2"> On {d.updated_date ? moment.tz(d.updated_date, userinfo.timezone ? userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}</span></>
                    : d.stock_access_status === 2  &&   d.req_status !== 2 && d.api_status !== 2 ? <button class="btn btn-primary btn-sm" >Request Feed</button>
                    : d.stock_access_status === 2  &&   d.req_status === 2 && d.api_status === 2 ? <><span class="text-danger font-weight-bold">Access Declined</span>  <br /><span className="small text-muted px-2"> On {d.updated_date ? moment.tz(d.updated_date, userinfo.timezone ? userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}</span></>
                    : d.api_status === 2 ? <><span class="text-danger font-weight-bold">Access Denied</span>  <br /><span className="small text-muted px-2"> On {d.updated_date ? moment.tz(d.updated_date, userinfo.timezone ? userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}</span></>
                    : d.req_status ===1 ? <><span class="text-success font-weight-bold">Approved</span> <br /><span className="small text-muted px-2"> On {d.updated_date ? moment.tz(d.updated_date, userinfo.timezone ? userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}</span></>
                    : "-" }
                </>
                // <>
                //     {d.req_status === 4 ? <button class="btn btn-primary btn-sm" onClick={() => requestfeed()}>Request Feed</button>
                //     : d.req_status === 2 ? <><span class="text-danger font-weight-bold">Access Denied</span><br /><span className="small text-muted px-2"> On {dateFormate(d.updated_date)}</span></>
                //     : d.stock_access_status === 2 && d.req_status === 3 ? <><span class="text-warning font-weight-bold">Requested</span>  <br /><span className="small text-muted px-2"> On {dateFormate(d.updated_date)}</span></>
                //     : d.status === 1 ? <span class="text-danger font-weight-bold d-inline-block px-2 py-0">Supplier Account Inactive</span>
                //     : d.stock_status === 1 ? <span class="text-danger font-weight-bold d-inline-block px-2 py-0">Supplier Account Inactive</span>
                //     : expiry ? <><span class="text-danger font-weight-bold d-inline-block px-2 py-0">Stock Expired </span>  <br /><span className="small text-muted px-2"> On {expiry}</span></>
                //     : d.req_status === 1  &&   d.api_status === 1 ? <><span class="text-success font-weight-bold font-weight-bold">Approved</span>  <br /><span className="small text-muted px-2"> On {dateFormate(d.updated_date)}</span></>
                //     : d.stock_access_status === 2  &&   d.req_status !== 2 && d.api_status !== 2 ? <button class="btn btn-primary btn-sm" >Request Feed</button>
                //     : d.stock_access_status === 2  &&   d.req_status === 2 && d.api_status === 2 ? <><span class="text-danger font-weight-bold">Access Declined</span>  <br /><span className="small text-muted px-2"> On {dateFormate(d.updated_date)}</span></>
                //     : d.api_status === 2 ? <><span class="text-danger font-weight-bold">Access Denied</span>  <br /><span className="small text-muted px-2"> On {dateFormate(d.updated_date)}</span></>
                //     : d.req_status ===1 ? <><span class="text-success font-weight-bold">Approved</span> <br /><span className="small text-muted px-2"> On {dateFormate(d.updated_date)}</span></>
                //     : "-" }
                // </>

            );
        },
        className:"text-center align-middle",
    },
   
]