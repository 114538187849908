import React, { useEffect, useState } from "react";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import SweetAlert from "react-bootstrap-sweetalert";
import Cookies from "js-cookie"
import {
    Backdrop,
    CircularProgress,
    makeStyles,
} from "@material-ui/core";
import axios from "axios";
import { NODE_API_URL,BASE_URL } from "../../../../env_config";
import { AddShippingAddress } from "../../Popup/AddShippingAddress";

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));


export default function ShippingAddress({userinfo,setShowEditShipping,contactbookuserinfo,language,setEditShipping,editShipping,setShippingAddress,shippingaddress,setMount,mount}) {
    const [warnMsg,setWarnMsg] = useState('')
    const [showDefaultShipAddAlert, setShowDefaultShipAddAlert] = useState(false);
    const [showDeleteShipAddAlert, setShowDeleteShipAddAlert] = useState(false);
    const [successMessage,setSuccessMessage] = React.useState(false)
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [openLoader,setOpenLoader] = useState(false)
    const [showAddShippingAddress,setShowAddShippingAddress]=useState(false)
    // const [showShippingAddress,setShowShippingAddress]=useState(false)
    // const [editShipping,setEditShipping] = useState({})
    const classes = useStyles();
    // const [shippingaddress,setShippingAddress] = useState([])
    // const [mount,setMount] = useState(false)
    useEffect(() => {
        if(userinfo && userinfo.id){
            axios({
                method: "POST",
                url: `${NODE_API_URL}/INTL/get-all-shipping-address`,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Cookies.get("cust_auth_token")}`
                },
                data:{
                    user_id:userinfo.id
                }
            }).then((res) => {
                // console.log(res.data,"resres")
                if(res && res.data && res.data.data?.length){
                    setShippingAddress(res.data.data)
                    if(res.data.message === "UnAuthorized"){
                        localStorage.clear()
                        window.location.reload(true)
                    }
                }
            }).
            catch(error => {
              if(error?.response?.data?.message === "UnAuthorized!"){
                var cookies = document.cookie.split(";");
              for (var i = 0; i < cookies.length; i++) {
                if(cookies[i] && !cookies[i].includes("view")){
                  var equals = cookies[i].indexOf("=");
                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              }
              
              window.location.replace(`${BASE_URL}/logout`);
            }
            })
        }

    },[mount,userinfo])
    const enableLoading = () => {
        // setLoading(true);
        setOpenLoader(true);
    };
    const disableLoading = () => {
        setOpenLoader(false);
    };

    const deleteShippingAddress = (data)=>{
        enableLoading();
        setShowDeleteShipAddAlert(data)
    }

    const deleteShippingAddressConfirm = ()=>{
        enableLoading();
        axios({
            method: "POST",
            url: `${NODE_API_URL}/INTL/delete-shipping-address`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}`
            },
            data:{
                user_id:showDeleteShipAddAlert.user_id,
                id:showDeleteShipAddAlert.id
            }
        }).then((res) => {
            // console.log(res.data,"resres")
            if(res && res.data){
                if(res.data.message === "UnAuthorized"){
                    localStorage.clear()
                    window.location.reload(true)
                }
                disableLoading()
                setShowDeleteShipAddAlert(false)
                setShowSuccessAlert(true)
                disableLoading();
                setSuccessMessage("Address Deleted Successfully !")
            }
        }).
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
    }

    const defaultShippingAddress = (data)=>{
        enableLoading();
        setShowDefaultShipAddAlert(data)
    }

    const DefaultShippingAddressConfirm = ()=>{
        axios({
            method: "POST",
            url: `${NODE_API_URL}/INTL/update-by-default-column`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}`
            },
            data:{
                user_id:showDefaultShipAddAlert.user_id,
                id:showDefaultShipAddAlert.id,
                default_address:"Y"
            }
        }).then((res) => {
            // console.log(res.data,"resres")
            if(res && res.data){
                if(res.data.message === "UnAuthorized"){
                    localStorage.clear()
                    window.location.reload(true)
                }
                disableLoading()
                setShowDefaultShipAddAlert(false)
                setShowSuccessAlert(true)
                disableLoading();
                setSuccessMessage("Address set as default shipping address !")
            }
        }).
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
    }

    console.log(shippingaddress,"shippingaddress")
    return (
        <>{warnMsg && (
            <SweetAlert
                warning
                title={"Warning!"}
                onConfirm={()=>setWarnMsg(false)}
                confirmBtnText="Ok"
                // cancelBtnText="Cancel"
                confirmBtnBsStyle="primary"
                // cancelBtnBsStyle="danger"
            >
                {warnMsg}
            </SweetAlert>
        )}
            {showSuccessAlert && (
                <SweetAlert
                    success
                    title="Success"
                    onConfirm={() => {setShowSuccessAlert(false);setMount(!mount)}}
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="primary"
                >
                   {successMessage}
                </SweetAlert>
            )}
            {showDeleteShipAddAlert && (
                <SweetAlert
                    warning
                    title="Are you sure ?"
                    onConfirm={() => deleteShippingAddressConfirm()}
                    onCancel={() => {
                        setShowDeleteShipAddAlert(false);
                        disableLoading()
                    }}
                    showCancel
                    confirmBtnText="Yes, Delete!"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="secondary"
                >
                    Are you sure you want to Delete this Address ?
                </SweetAlert>
            )} 
            {showDefaultShipAddAlert && (
                <SweetAlert
                    warning
                    title="Are you sure ?"
                    onConfirm={() => DefaultShippingAddressConfirm()}
                    onCancel={() => {
                        setShowDefaultShipAddAlert(false);
                        disableLoading()
                    }}
                    showCancel
                    confirmBtnText="Yes, Set!"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="secondary"
                >
                    Are you sure you want to set this address as default shipping address ?
                </SweetAlert>
            )} 
            <AddShippingAddress show={showAddShippingAddress} onHide={ ()=> setShowAddShippingAddress(false) } data={userinfo} setMount={setMount} mount={mount} editShipping={editShipping} setEditShipping={setEditShipping} shippingaddress={shippingaddress}/>
            <Backdrop className={classes.backdrop} open={openLoader} >
                <div className="px-5 py-3 bg-white rounded shadow-sm d-flex justify-content-center align-items-center"><CircularProgress size="30px" color="secondary" /> <span className="pl-2 font-weight-bold text-dark">Processing..</span></div>
            </Backdrop>
            <div className="row">
                <div className="col-12 border-bottom d-flex justify-content-between pb-3 mb-5">
                    <h3>Manage {language.PROFILEVIEW_SHIPPING_INFORMATION}</h3>
                    <button className="btn btn-hover-light-dark" onClick={()=>{
                        if(shippingaddress.length >= 5){
                            setWarnMsg("Address Limit Exceeded, You will need to either delete or modify the existing addresses.")
                            return
                        }
                        setShowEditShipping("Add")
                    }}> <MdOutlineAddLocationAlt className="font-size-h4" /> Add new address</button>
                </div>
                <div className="col-12 px-8 mb-5 d-flex flex-wrap custom-scrollbar" style={{maxHeight:"66vh"}}>
                    {shippingaddress.map((value,index) => (
                        <div className={`shipping-address-sec ${value.default_address === "Y"?"active-ship-add":""}`}>
                        <div className="mb-2">
                            <div className="text-muted mr-2" >{language.INVOICE_DETAIL_ADDRESS}:</div>
                            <div>
                                <div className="text-capitalize">{userinfo.company_name_official}</div>
                                <div  className="text-capitalize">{value.name_atten}</div>
                                <div>{value.shipping_address_1}, {value.shipping_address_2 ? <> {value.shipping_address_2}</> : null} {value.shipping_address_3 ? <>, {value.shipping_address_3},</>:null}</div>
                                <div>{value.shipping_city} {value.shipping_state ? <>,{value.shipping_state}</>:""}, {value.shipping_zip_code},</div>
                                <div>{value.shipping_country}</div>
                            </div>
                        </div>
                        {value.fedex_ac_no && <div><span className="text-muted mb-2 mr-2">{language.PROFILE_EDIT_FEDEX_ACC_NO}:</span>{value.fedex_ac_no}</div>}
                        <div><span className="text-muted mb-2 mr-2">{language.PROFILE_EDIT_SHIPPING_EMAIL}:</span>{value.shipping_email}</div>
                        <div><span  className="text-muted mb-2 mr-2">{language.PROFILE_EDIT_TELEPHONE}:</span>{value.shipping_phone}</div>
                        <div><span className="text-muted mb-2  mr-2">Port Of Discharge:</span>{value.port_of_discharge}</div>
                        {userinfo.kyc_status !== "KYC_PEND"?
                            <div className="mt-5">
                                <span 
                                    className="text-primary mx-3 cursor-pointer" 
                                    onClick={()=> {
                                            // setShowAddShippingAddress("Edit");
                                            setShowEditShipping("Edit")
                                            setEditShipping(value)
                                        }
                                    }
                                >
                                    Edit
                                </span>
                                 {value.default_address === "N"? 
                                    <>| <span className="text-primary mx-3 cursor-pointer" onClick={()=>deleteShippingAddress(value)}>Remove</span> | <span className="text-primary mx-2 cursor-pointer" onClick={()=>defaultShippingAddress(value)}>Set As Default</span></>
                                 :<>| <span className="text-primary mx-3 cursor-pointer">Default Selected</span></>} 
                            </div>
                        :""}
                    </div>
                    ))}
                    {/* <div className="shipping-address-sec active-ship-add">
                        <div className="mb-2">
                            <div className="text-muted mr-2" >{language.INVOICE_DETAIL_ADDRESS}:</div>
                            <div>
                                <div className="text-capitalize">{userinfo.cname}</div>
                                <div  className="text-capitalize">tena doc</div>
                                <div>Address 1</div>
                                <div>Address 2</div>
                                <div>address 3</div>
                                <div>{userinfo.city}</div>
                                <div>{userinfo.state}</div>
                                <div>{userinfo.country}</div>
                            </div>
                        </div>
                        <div><span className="text-muted mb-2 mr-2">{language.PROFILE_EDIT_SHIPPING_EMAIL}:</span>{userinfo.shipping_email}</div>
                        <div><span  className="text-muted mb-2 mr-2">{language.PROFILE_EDIT_TELEPHONE}:</span>{userinfo.shipping_phone}</div>
                        <div><span className="text-muted mb-2 mr-2">{language.PROFILE_EDIT_COMPANY_TAX}:</span>{userinfo.company_tax}</div>
                        <div><span className="text-muted mb-2  mr-2">Port Of Discharge:</span>{userinfo.port_of_discharge}</div>
                        <div className="mt-5"><span className="text-primary mx-2 cursor-pointer">Edit</span></div>
                    </div>
                    <div className="shipping-address-sec">
                        <div className="mb-2">
                            <div className="text-muted mr-2" >{language.INVOICE_DETAIL_ADDRESS}:</div>
                            <div>
                                <div className="text-capitalize">{userinfo.cname}</div>
                                <div  className="text-capitalize">tena doc</div>
                                <div>Address 1</div>
                                <div>Address 2</div>
                                <div>address 3</div>
                                <div>{userinfo.city}</div>
                                <div>{userinfo.state}</div>
                                <div>{userinfo.country}</div>
                            </div>
                        </div>
                        <div><span className="text-muted mb-2 mr-2">{language.PROFILE_EDIT_SHIPPING_EMAIL}:</span>{userinfo.shipping_email}</div>
                        <div><span  className="text-muted mb-2 mr-2">{language.PROFILE_EDIT_TELEPHONE}:</span>{userinfo.shipping_phone}</div>
                        <div><span className="text-muted mb-2 mr-2">{language.PROFILE_EDIT_COMPANY_TAX}:</span>{userinfo.company_tax}</div>
                        <div><span className="text-muted mb-2  mr-2">Port Of Discharge:</span>{userinfo.port_of_discharge}</div>
                        <div className="mt-5"><span className="text-primary mx-3 cursor-pointer">Edit</span> | <span className="text-primary mx-3 cursor-pointer" onClick={()=>deleteShippingAddress()}>Remove</span> | <span className="text-primary mx-2 cursor-pointer" onClick={()=>defaultShippingAddress()}>Set As Default</span> </div>
                    </div> */}
                </div>
               
            </div>
        </>
    );
}
